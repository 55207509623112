// module
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import Form from '../../../components/form/Form';
import Loading from '../../../components/Loading';
// actions
import { setLog } from '../../../action_creators/logActionCreators';
import { setAdmin } from '../../../action_creators/adminActionCreators';
// functions
import {
  getRequest,
  postRequest,
  responseManagment,
} from '../../../functions/ajax';

const form_items = [
  {
    type: 'text',
    name: 'object',
    label: `Objet`,
    required: true,
  },
  {
    type: 'textarea',
    name: 'message',
    label: `Message`,
    required: true,
  },
];

class Contact extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      is_loading: false,
      is_sending: false,
    };

    this.responseManagment = responseManagment.bind(this);
    this.postMessage = this.postMessage.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (!Object.keys(this.props.admin).length) {
      this.getAdmin();
    }
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  async getAdmin() {
    this.setState({ is_loading: true });
    const response = await getRequest(
      `/property_admin?activity=${this.props.properties.current.id}`,
      this.props.user.token
    );
    if (this.responseManagment(response)) {
      this.props.setAdmin(response.property_admin);
    }
    if (this._isMounted) {
      this.setState({ is_loading: false });
    }
  }

  async postMessage(form_value) {
    this.setState({ is_sending: true });
    const response = await postRequest(
      `/message-gestionnaire?activity=${this.props.properties.current.id}`,
      form_value,
      this.props.user.token
    );
    if (this.responseManagment(response)) {
      this.props.setLog({
        type: 'success',
        message: 'Message envoyé au gestionnaire avec succès.',
      });
    }

    if (this._isMounted) {
      this.setState({ is_sending: false });
    }
  }

  render() {
    return (
      <div className="segment">
        {this.state.is_loading === false ? (
          Object.keys(this.props.admin).length ? (
            <Fragment>
              <h3>Contacter votre gestionnaire</h3>
              {this.state.is_sending === false ? (
                <Form form_items={form_items} callback={this.postMessage} />
              ) : (
                <Loading />
              )}
            </Fragment>
          ) : (
            <p>
              Vous n'avez plus de gestionnaire, vous ne pouvez donc pas le
              contacter.
            </p>
          )
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    properties: state.properties,
    admin: state.admin,
  };
};
const mapDispatchToProps = {
  setLog,
  setAdmin,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Contact)
);
