// module
import React, { Component } from 'react';
import PropTypes from 'prop-types';

const mb_to_b = 1048576;

class ImageItem extends Component {
	constructor(props) {
		super(props);

		this.state = { dragging: false };

		this.handleDrag = this.handleDrag.bind(this);
		this.handleDrop = this.handleDrop.bind(this);
		this.handleImages = this.handleImages.bind(this);
	}

	handleImages(evt) {
		this.sendImages(evt.target.files);
	}

	sendImages(images) {
		let send_images = [];
		if (images.length) {
			for (let [i, image] of Object.entries(images)) {
				if (i > this.props.nb_max - 1) {
					this.props.setLog({
						type: 'error',
						message: `Vous essayez d'importer plus ${
							this.props.nb_max > 1
								? `de ${this.props.nb_max} images`
								: "d'une image"
						}.`,
					});
					break;
				}
				if (
					image.type !== 'image/jpeg'
					&& image.type !== 'image/png'
					&& image.type !== 'image/gif'
				) {
					this.props.setLog({
						type: 'error',
						message: "Le format de l'image est incorrecte.",
					});
					continue;
				}
				if (image.size > this.props.max_Mo * mb_to_b) {
					this.props.setLog({
						type: 'error',
						message: `L'image est trop volumineuse, elle fait plus de ${this.props.max_Mo}Mo.`,
					});
					continue;
				}
				send_images.push(image);
			}
			this.props.callback(send_images);
		}
	}

	handleDrag(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		if (evt.type === 'dragenter' || evt.type === 'dragover') {
			evt.dataTransfer.dropEffect = 'copy';
			this.setState({ dragging: true });
		} else if (evt.type === 'dragleave') {
			this.setState({ dragging: false });
		}
	}

	handleDrop(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		this.sendImages(evt.dataTransfer.files);
		this.setState({ dragging: false });
	}

	render() {
		return (
			<div
				className={`form-item-image-wrapper ${
					this.state.dragging ? ' dragging' : ''
				}`}
				onDragEnter={this.handleDrag}
				onDragLeave={this.handleDrag}
				onDragOver={this.handleDrag}
				onDrop={this.handleDrop}
			>
				<span className="fake-label">
					{this.props.label}
					{this.props.required ? '\u00A0*' : ''}
				</span>
				<div className="form-item-image">
					<div className="d-flex flex-column mb-3">
						<span>
							Types de fichiers autorisés&nbsp;: gif&nbsp;; jpg&nbsp;;
							jpeg&nbsp;; png
						</span>
						<span>Taille maximale&nbsp;: {this.props.max_Mo}Mo</span>
					</div>
					{this.props.nb_max > 1 && (
						<span>{this.props.nb_max} fichiers maximum</span>
					)}
					<div className="form-group">
						<label
							className={
								'btn outline-btn blue'
								+ (this.props.checked ? ' checked' : '')
							}
							htmlFor="images"
						>
							{this.props.label_btn}
						</label>
						<input
							type="hidden"
							name="MAX_FILE_SIZE"
							value={this.props.max_Mo * mb_to_b}
						/>
						<input
							type="file"
							accept="image/gif,image/jpeg,image/png"
							id="images"
							name="images"
							className="input-image"
							onChange={this.handleImages}
							multiple={this.props.nb_max > 1 ? 'multiple' : false}
						/>
					</div>
				</div>
			</div>
		);
	}
}

ImageItem.propTypes = {
	label: PropTypes.string.isRequired,
	label_btn: PropTypes.string.isRequired,
	title: PropTypes.string,
	nb_max: PropTypes.number.isRequired,
	max_Mo: PropTypes.number.isRequired,
	required: PropTypes.bool,
	checked: PropTypes.any,
	callback: PropTypes.func.isRequired,
	setLog: PropTypes.func.isRequired,
};

ImageItem.defaultProps = {
	required: false,
};

export default ImageItem;
