// module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import TableLeases from '../../../components/table/TableLeases';
import Loading from '../../../components/Loading';
//action
import {
	setLeases,
	setCurrentLease,
} from '../../../action_creators/leasesActionCreators';
import { setLog } from '../../../action_creators/logActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';
import Title from '../../../components/Title';

class MyLeases extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			are_loading_leases: false,
			is_loading_lease: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.viewLeaseDetails = this.viewLeaseDetails.bind(this);
		this.goBack = this.goBack.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.getLeases();
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async getLeases() {
		if (!Object.keys(this.props.leases.list).length) {
			this.setState({ are_loading_leases: true });
			const response = await getRequest(
				`/leases?activity=${this.props.properties.current.id}`,
				this.props.user.token
			);
			if (this.responseManagment(response)) {
				this.props.setLeases(response.leases);
			}

			if (this._isMounted) {
				this.setState({ are_loading_leases: false });
			}
		}
	}

	viewLeaseDetails(leaseId) {
		this.props.history.push(
			`/property/${this.props.properties.current.id}/data/leases/${leaseId}`
		);
	}

	goBack(evt) {
		this.props.history.push(`/property/${this.props.match.params.id}/data`);
	}

	render() {
		const leases = Object.values(this.props.leases.list);
		return (
			<>
				<Title title="Mes biens" />
				<div className="my-leases">
					<div className="page-title">
						<button
							className="btn back-btn"
							onClick={evt => this.goBack(evt)}
						>
							<img
								src="/images/pictogrammes/back-arrow-blue.svg"
								alt="retour"
							/>
						</button>
						<h2>Tous mes baux</h2>
					</div>
					<div className="table-wrapper">
						{this.state.are_loading_leases === false ? (
							leases.length ? (
								<TableLeases
									cols={[
										'LIBELLÉ',
										'LOCATAIRE',
										'DATE DE FIN',
										'PROCHAIN LOYER',
										'DÉTAILS',
									]}
									table={leases}
									viewLeaseDetails={this.viewLeaseDetails}
								/>
							) : (
								<p>Aucun bail disponible sur cette propriété.</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		properties: state.properties,
		user: state.user,
		leases: state.leases,
	};
};
const mapDispacthToProps = {
	setLeases,
	setCurrentLease,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(MyLeases));
