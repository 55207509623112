//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
// components
import { SelectorItemObject } from '../../../../components/form/item/SelectorItem';
import Loading from '../../../../components/Loading';
//action
import { setLog } from '../../../../action_creators/logActionCreators';
import {
	setGlobalProfits,
	setProfitExercices,
	setProfitCurrentExercice,
} from '../../../../action_creators/profitActionCreators';
// functions
import { getRequest, responseManagment } from '../../../../functions/ajax';
import { formatNumbers } from '../../../../functions';

class ProfitCard extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.handleExercice = this.handleExercice.bind(this);
	}

	async UNSAFE_componentWillMount() {
		const profit = this.props.profit;
		const history = profit.history;
		if (
			!Object.keys(profit.amounts).length
			// Update transactions if exerice has change in another page
			|| profit.current_exercice !== history.global.exercice
		) {
			this.getProfit();
		}
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.profit.current_exercice !== this.props.profit.current_exercice) {
			this.updateProfit();
		}
	}

	// get and update value should be the same do to cross exercices and operation
	async getProfit() {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/rentabilite-des-lots?activity=${this.props.properties.current.id}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setProfitExercices(response.exercices);
			this.props.setGlobalProfits(response.profit);
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	async updateProfit() {
		const current_exercice =
			this.props.profit.exercices[this.props.profit.current_exercice];
		this.setState({ is_loading: true });

		const response = await getRequest(
			`/rentabilite-des-lots?`
				+ `activity=${this.props.properties.current.id}`
				+ `&start=${current_exercice.start}`
				+ `&end=${current_exercice.end}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setGlobalProfits(response.profit);
		} else {
			this.props.setGlobalProfits({});
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	handleExercice(evt) {
		this.props.setProfitCurrentExercice(evt.target.value);
	}

	render() {
		return (
			<div className="card unscrollable profit">
				<div className="card-header">
					<h2>Rentabilité des lots</h2>
				</div>
				<div className="card-body">
					{this.state.is_loading === false ? (
						Object.keys(this.props.profit.amounts).length ? (
							<>
								<div className="standfirst">
									{this.props.profit.exercices.length > 0 && (
										<SelectorItemObject
											id={`select-card-profit-exercices`}
											label="Période"
											current={this.props.profit.current_exercice}
											options={this.props.profit.exercices}
											callback={this.handleExercice}
										/>
									)}
								</div>
								<div className="table-wrapper">
									<table className="table horizontal table-profit">
										<tbody>
											<tr>
												<th>Montant total des Loyers</th>
												<td>
													{formatNumbers(
														this.props.profit.amounts.rents
															|| 0
													)}{' '}
													€
												</td>
											</tr>
											<tr>
												<th>Montant total des Charges</th>
												<td>
													{formatNumbers(
														this.props.profit.amounts.spent
															|| 0
													)}{' '}
													€
												</td>
											</tr>
											<tr>
												<th>Résultat</th>
												<td>
													{formatNumbers(
														this.props.profit.amounts.result
															|| 0
													)}{' '}
													€
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</>
						) : (
							<p>Aucune rente n'est disponible pour cette période.</p>
						)
					) : (
						<Loading />
					)}
					<div className="btn-wrapper">
						<Link
							className="btn main-btn"
							to={`/property/${this.props.match.params.id}/managment/profit`}
						>
							Accéder
						</Link>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		properties: state.properties,
		user: state.user,
		profit: state.profit,
	};
};
const mapDispatchToProps = {
	setLog,
	setGlobalProfits,
	setProfitExercices,
	setProfitCurrentExercice,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfitCard));
