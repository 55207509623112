//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import {
	SelectorItemBase,
	SelectorItemObject,
} from '../../../components/form/item/SelectorItem';
import PrintButton from '../../../components/button/PrintButton';
import TableAccount from '../../../components/table/TableAccount';
import Loading from '../../../components/Loading';
import Title from '../../../components/Title';
//action
import { setLog } from '../../../action_creators/logActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';
import { printTable } from '../../../functions/print';

class TenantAccount extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
			tenants: [],
			transactions: [],
			exercices: [],
			current_tenant: 0,
			current_exercice: 0,
			account: '',
		};

		this.responseManagment = responseManagment.bind(this);
		this.goBack = this.goBack.bind(this);
		this.handleExercice = this.handleExercice.bind(this);
		this.handleTenant = this.handleTenant.bind(this);
		this.handlePrint = this.handlePrint.bind(this);
		this.setOwnPrintFilters = this.setOwnPrintFilters.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.checkParams();
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.current_exercice !== this.state.current_exercice) {
			this.updateAccount();
		}
		if (prevState.current_tenant !== this.state.current_tenant) {
			this.updateAccount();
		}
	}

	checkParams() {
		let { tenant_id } = this.props.match.params;

		if (tenant_id === undefined) {
			this.goBack();
		} else if (isNaN(tenant_id) || tenant_id < 1) {
			this.goBack();
		} else {
			this.getAccount(tenant_id);
		}
	}

	async getAccount(tenant_id) {
		if (!this.state.transactions.length) {
			this.setState({ is_loading: true });
			const response = await getRequest(
				`/compte-locataire/${tenant_id}`
					+ `?activity=${this.props.properties.current.id}`,
				this.props.user.token
			);
			if (this._isMounted) {
				if (this.responseManagment(response)) {
					this.setState({
						transactions: response.compte,
						exercices: response.exercices,
						account: response.compteNumber,
						current_tenant: tenant_id,
						tenants: response.tenants,
						is_loading: false,
					});
				} else {
					this.setState({ is_loading: false });
				}
			}
		}
	}

	async updateAccount() {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/compte-locataire/${this.state.current_tenant}?`
				+ `activity=${this.props.properties.current.id}`
				+ `&start=${this.state.exercices[this.state.current_exercice].start}`
				+ `&end=${this.state.exercices[this.state.current_exercice].end}`,
			this.props.user.token
		);
		if (this._isMounted) {
			if (this.responseManagment(response)) {
				this.setState({
					transactions: response.compte,
					account: response.compteNumber,
					is_loading: false,
				});
			} else {
				this.setState({
					transactions: [],
					account: '',
					is_loading: false,
				});
			}
		}
	}

	handleExercice(evt) {
		this.setState({ current_exercice: evt.target.value });
	}

	handleTenant(evt) {
		this.setState({ current_tenant: evt.target.value });
	}

	handlePrint(evt) {
		printTable(
			'table-account',
			`compte-locataire-${this.state.tenants[this.state.current_tenant].name}.pdf`,
			`Le compte locataire de ${
				this.state.tenants[this.state.current_tenant].name
			}`,
			this.props.properties.current,
			this.setOwnPrintFilters
		);
	}

	setOwnPrintFilters() {
		return `Compte : ${this.state.account} | Période : ${
			this.state.exercices[this.state.current_exercice].label
		} | Locataire : ${this.state.tenants[this.state.current_tenant].name}`;
	}

	goBack(evt) {
		if (this.props.history.length < 3) {
			this.props.history.push(`/property/${this.props.match.params.id}/account/`);
		} else {
			this.props.history.goBack();
		}
	}

	render() {
		let tenants = Object.entries(this.state.tenants);
		// Sort tenants by names
		tenants = tenants.sort((t1, t2) => {
			if (t1[1].name > t2[1].name) {
				return 1;
			}
			if (t1[1].name < t2[1].name) {
				return -1;
			}
			return 0;
		});
		return (
			<>
				<Title title="Mes comptes" />
				<div className="my-tenant-account">
					<div className="page-title">
						<button
							className="btn back-btn"
							onClick={evt => this.goBack(evt)}
						>
							<img
								src="/images/pictogrammes/back-arrow-blue.svg"
								alt="retour"
							/>
						</button>
						<h2>Détails compte locataire</h2>
						<PrintButton print={this.handlePrint} />
					</div>
					<div className="standfirst">
						{this.state.account ? <h3>Compte {this.state.account}</h3> : null}
						{this.state.exercices.length > 0 && (
							<SelectorItemObject
								id="select-account-exerice"
								label="Période"
								current={this.state.current_exercice}
								options={this.state.exercices}
								callback={this.handleExercice}
							/>
						)}
						{tenants.length > 0 && (
							<SelectorItemBase
								id="select-tenant"
								label="Locataire"
								current={this.state.current_tenant}
								callback={this.handleTenant}
							>
								{tenants.map(([key, tenant], i) => (
									<option key={i} value={key}>
										{tenant.name}
									</option>
								))}
							</SelectorItemBase>
						)}
					</div>
					{this.state.is_loading === false ? (
						this.state.transactions.length ? (
							<div className="table-wrapper">
								<TableAccount
									cols={[
										'DATE',
										'LIBELLÉS',
										'MONTANT',
										'SOLDE',
										'PIÈCE',
									]}
									table={this.state.transactions}
									activity={this.props.properties.current.id}
									solde
								/>
							</div>
						) : (
							<p>
								Il n'y a aucune opération sur ce compte à cette période.
							</p>
						)
					) : (
						<Loading />
					)}
				</div>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		properties: state.properties,
		user: state.user,
	};
};
const mapDispatchToProps = {
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TenantAccount));
