import React from 'react';
import { formatNumbers } from '../../functions';

const TableRent = props => (
	<table className="table horizontal table-rent">
		<tbody>
			<tr>
				<th>Loyer TTC</th>
				<td>
					{formatNumbers(
						props.table.rent.value * (1 + props.table.rent.tva_rent / 100)
					)}
					&nbsp;€ (dont TVA x €)
				</td>
			</tr>
			<tr>
				<th>Provisions pour charges</th>
				<td>
					{formatNumbers(
						props.table.rent.charges_provisions
							* (1 + props.table.rent.tva_charges_provisions / 100)
					)}
					&nbsp;€ (dont TVA x €)
				</td>
			</tr>
			<tr>
				<th>Dépôt de garantie</th>
				<td>{formatNumbers(props.table.rent.security_deposits || 0)} €</td>
			</tr>
			<tr>
				<th>Periodicité</th>
				<td>{props.table.rent.periodicity}</td>
			</tr>
			<tr>
				<th>Prochain appel</th>
				<td>{props.table.next_call || '-'}</td>
			</tr>
		</tbody>
	</table>
);

export default TableRent;
