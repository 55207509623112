//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import { SelectorItemObject } from '../../../components/form/item/SelectorItem';
import TableHonoraries from '../../../components/table/TableHonoraries';
import Loading from '../../../components/Loading';
//action
import { setLog } from '../../../action_creators/logActionCreators';
import {
	setHonoraries,
	setHonorariesExercices,
	setHonorariesCurrentExercice,
} from '../../../action_creators/honorariesActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';
import Title from '../../../components/Title';

class MyManagment extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		};

		this.goBack = this.goBack.bind(this);
		this.responseManagment = responseManagment.bind(this);
		this.handleExercice = this.handleExercice.bind(this);
	}

	async UNSAFE_componentWillMount() {
		this.getHonoraries();
	}

	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps) {
		const honoraries = this.props.honoraries;
		// Filter change exercice
		if (
			prevProps.honoraries.current_exercice !== honoraries.current_exercice
			|| prevProps.honoraries.current_lot !== honoraries.current_lot
		) {
			this.updateHonoraries();
		}
	}

	async getHonoraries() {
		if (!this.props.honoraries.list.length) {
			this.setState({ is_loading: true });
			const response = await getRequest(
				`/honoraire?activity=${this.props.properties.current.id}`,
				this.props.user.token
			);
			if (this.responseManagment(response)) {
				this.props.setHonoraries(response.honoraires);
				this.props.setHonorariesExercices(response.exercices);
			}

			if (this._isMounted) {
				this.setState({ is_loading: false });
			}
		}
	}

	async updateHonoraries() {
		this.setState({ is_loading: true });
		const current_exercice =
			this.props.honoraries.exercices[this.props.honoraries.current_exercice];
		const response = await getRequest(
			`/honoraire`
				+ `?activity=${this.props.properties.current.id}`
				+ `&start=${current_exercice.start}`
				+ `&end=${current_exercice.end}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setHonoraries(response.honoraires);
		} else {
			this.props.setHonoraries({});
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	handleExercice(evt) {
		this.props.setHonorariesCurrentExercice(evt.target.value);
	}

	goBack(evt) {
		this.props.history.push(`/property/${this.props.match.params.id}/managment/`);
	}

	render() {
		return (
			<>
				<Title title="Ma gestion" />
				<div className="my-honoraries">
					<div className="page-title">
						<button
							className="btn back-btn"
							onClick={evt => this.goBack(evt)}
						>
							<img
								src="/images/pictogrammes/back-arrow-blue.svg"
								alt="retour"
							/>
						</button>
						<h2>Honoraires, factures & autres charges</h2>
					</div>
					<div className="standfirst">
						{this.props.honoraries.exercices.length > 0 && (
							<SelectorItemObject
								id="select-honoraries-exerice"
								label="Période"
								current={this.props.honoraries.current_exercice}
								options={this.props.honoraries.exercices}
								callback={this.handleExercice}
							/>
						)}
					</div>
					{this.state.is_loading ? (
						<Loading />
					) : this.props.honoraries.list.length ? (
						<div className="table-wrapper">
							<TableHonoraries
								table={this.props.honoraries.list}
								cols={[
									'DATE',
									'TYPE',
									'MONTANT',
									'DOCUMENTS',
								]}
								activity={this.props.properties.current.id}
							/>
						</div>
					) : (
						<p>Aucune donnée sur cette période.</p>
					)}
				</div>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		properties: state.properties,
		user: state.user,
		honoraries: state.honoraries,
	};
};
const mapDispatchToProps = {
	setLog,
	setHonoraries,
	setHonorariesExercices,
	setHonorariesCurrentExercice,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyManagment));
