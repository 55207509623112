// module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
// components
import Table from '../../../components/table/Table';
import TableAdmin from '../../../components/table/TableAdmin';
import TableLeases from '../../../components/table/TableLeases';
import Loading from '../../../components/Loading';
//action
import { setLots } from '../../../action_creators/lotsActionCreators';
import {
	setLeases,
	setCurrentLease,
} from '../../../action_creators/leasesActionCreators';
import { setAdmin } from '../../../action_creators/adminActionCreators';
import { setLog } from '../../../action_creators/logActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';
// const
import Title from '../../../components/Title';

class MyData extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			are_loading_lots: false,
			are_loading_leases: false,
			is_loading_lease: false,
			is_loading_admin: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.viewLeaseDetails = this.viewLeaseDetails.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.getLots();
		this.getLeases();
		this.getAdmin();
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async getLots() {
		if (!this.props.lots.length) {
			this.setState({ are_loading_lots: true });
			const response = await getRequest(
				`/lot?activity=${this.props.properties.current.id}`,
				this.props.user.token
			);
			if (this.responseManagment(response)) {
				this.props.setLots(response.lots);
			}

			if (this._isMounted) {
				this.setState({ are_loading_lots: false });
			}
		}
	}

	async getAdmin() {
		if (!Object.keys(this.props.admin).length) {
			this.setState({ is_loading_admin: true });
			const response = await getRequest(
				`/property_admin?activity=${this.props.properties.current.id}`,
				this.props.user.token
			);
			if (this.responseManagment(response)) {
				this.props.setAdmin(response.property_admin);
			}

			if (this._isMounted) {
				this.setState({ is_loading_admin: false });
			}
		}
	}

	async getLeases() {
		if (!Object.keys(this.props.leases.list).length) {
			this.setState({ are_loading_leases: true });
			const response = await getRequest(
				`/leases?activity=${this.props.properties.current.id}`,
				this.props.user.token
			);
			if (this.responseManagment(response)) {
				this.props.setLeases(response.leases);
			}

			if (this._isMounted) {
				this.setState({ are_loading_leases: false });
			}
		}
	}

	viewLeaseDetails(leaseId) {
		this.props.history.push(
			`/property/${this.props.properties.current.id}/data/leases/${leaseId}`
		);
	}

	render() {
		const leases = Object.values(this.props.leases.list);
		return (
			<>
				<Title title="Mes biens" />
				<div className="my-data responsive-column">
					<div className="card managed-lots">
						<div className="card-header">
							<h2>
								Mes biens gérés
								{this.props.lots.length
									? ` (${this.props.lots.length})`
									: ''}
							</h2>
						</div>
						<div className="card-body">
							{this.state.are_loading_lots === false ? (
								this.props.lots.length ? (
									<>
										<div className="table-wrapper">
											<Table
												className="table-lots"
												table={this.props.lots.slice(0, 4)}
												keys={[
													'reference',
													'label',
													'type',
													'principal',
												]}
												cols={['REF', 'LIBELLÉ', 'TYPE', 'LOUÉ']}
												boolean={['Non', 'Oui']}
											/>
										</div>
										{this.props.lots.length > 4 && (
											<div className="btn-wrapper">
												<Link
													className="btn main-btn"
													to={`/property/${this.props.match.params.id}/data/lots`}
												>
													Voir tous mes biens
												</Link>
											</div>
										)}
									</>
								) : (
									<p>Vous ne possédez pas de lots.</p>
								)
							) : (
								<Loading />
							)}
						</div>
					</div>
					<div className="card admin">
						<div className="card-header">
							<h2>Administrateur de biens</h2>
						</div>
						<div className="card-body table-wrapper">
							{this.state.is_loading_admin === false ? (
								Object.keys(this.props.admin).length ? (
									<TableAdmin table={this.props.admin} />
								) : (
									<p>
										Il n'y a pas d'administrateur de bien sur ce bien.
									</p>
								)
							) : (
								<Loading />
							)}
						</div>
					</div>
					<div className="card leases">
						<div className="card-header">
							<h2>
								Mes Baux
								{Object.values(this.props.leases.list).length
									? ` (${Object.values(this.props.leases.list).length})`
									: null}
							</h2>
						</div>
						<div className="card-body">
							{this.state.are_loading_leases === false ? (
								leases.length ? (
									<>
										<div className="table-wrapper">
											<TableLeases
												cols={[
													'LIBELLÉ',
													'LOCATAIRE',
													'ÉCHÉANCE BAIL',
													'PROCHAIN LOYER',
													'DÉTAILS',
												]}
												table={leases.slice(0, 4)}
												viewLeaseDetails={this.viewLeaseDetails}
											/>
										</div>
										{leases.length > 4 && (
											<div className="btn-wrapper">
												<Link
													className="btn main-btn"
													to={`/property/${this.props.match.params.id}/data/leases`}
												>
													Voir tous mes baux
												</Link>
											</div>
										)}
									</>
								) : (
									<p>Aucun bail disponible sur cette propriété.</p>
								)
							) : (
								<Loading />
							)}
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		properties: state.properties,
		user: state.user,
		lots: state.lots,
		leases: state.leases,
		admin: state.admin,
	};
};
const mapDispacthToProps = {
	setLots,
	setAdmin,
	setLeases,
	setCurrentLease,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(MyData));
