// module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// component
import Property from '../components/Property';
import Modal from '../components/Modal';
import Form from '../components/form/Form';
import Loading from '../components/Loading';
//actions
import { removeCurrentProperty } from '../action_creators/propertiesActionCreators';
import { setUserCGU } from '../action_creators/userActionCreators';
import { setLog } from '../action_creators/logActionCreators';
// functions
import { getRequest, responseManagment } from '../functions/ajax';
import Title from '../components/Title';

class MyProperties extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			display_cgu_modal: true,
			is_loading: false,
		};

		this.setCGU = this.setCGU.bind(this);
		this.responseManagment = responseManagment.bind(this);
	}

	UNSAFE_componentWillMount() {
		// Remove current Property if it exist
		if (Object.keys(this.props.properties.current).length) {
			this.props.removeCurrentProperty();
		}
		this.checkCGU();
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.user.cgu !== this.props.user.cgu) {
			this.checkCGU();
		}
	}

	// Don't ask CGU if it has been already done
	checkCGU() {
		if (this.props.user.cgu !== false) {
			this.toggleModal(false);
		}
	}

	async setCGU(value) {
		if (value.cgu) {
			this.setState({ is_loading: true });
			const response = await getRequest(`/user-cgu`, this.props.user.token);
			if (this.responseManagment(response)) {
				this.props.setUserCGU(new Date().toLocaleDateString('fr-FR'));
				this.props.setLog({
					type: 'success',
					message: "Conditions générales d'utilisation acceptées.",
				});
			}

			if (this._isMounted) {
				this.setState({ is_loading: false });
			}
		}
	}

	toggleModal(is_visible) {
		this.setState({ display_cgu_modal: is_visible });
	}

	render() {
		const properties = Object.values(this.props.properties.list);
		return (
			<>
				<Title title="Mes propriétés" />
				<div className="my-properties">
					<div className={'property-list' + (properties.length ? ' grid' : '')}>
						{properties.length ? (
							properties.map((property, i) => (
								<Property property={property} key={i} />
							))
						) : (
							<p>Vous n'avez aucune propriété.</p>
						)}
					</div>
					{/* Modal */}
					<Modal
						// This modal couldn't be hide by clicking on overlay
						id="my-property-modal-cgu"
						is_visible={this.state.display_cgu_modal}
					>
						<h2>Conditions Générales d'Utilisation</h2>
						<p>
							Afin de pouvoir utiliser notre logiciel, merci de bien vouloir
							accepter les conditions générales d'utilisation.
						</p>
						{this.state.is_loading === false ? (
							<Form
								form_items={[
									{
										type: 'checkbox',
										name: 'cgu',
										label:
											`J'accepte les\u00A0`
											+ `<a href="https://www.mygestionpro.com/" target="_blank">`
											+ `conditions générales d'utilisation`
											+ `</a>.`,
										required: true,
									},
								]}
								callback={this.setCGU}
							/>
						) : (
							<Loading />
						)}
					</Modal>
				</div>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		properties: state.properties,
		user: state.user,
	};
};
const mapDispatchToProps = {
	removeCurrentProperty,
	setUserCGU,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyProperties));
