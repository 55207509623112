import React, { Component } from 'react';
import Title from './Title';
import { Link, withRouter } from 'react-router-dom/cjs/react-router-dom.min';

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };

		this.clearError = this.clearError.bind(this);
		this.handleRefresh = this.handleRefresh.bind(this);
		this.handleContact = this.handleContact.bind(this);
		this.handleGoBack = this.handleGoBack.bind(this);
	}

	static getDerivedStateFromError(error) {
		// Mettez à jour l'état, de façon à montrer l'UI de repli au prochain rendu.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// Vous pouvez aussi enregistrer l'erreur au sein d'un service de rapport.
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			this.clearError();
		}
	}

	clearError() {
		this.setState({ hasError: false });
	}

	handleRefresh() {
		window.location.reload();
	}

	handleContact() {
		this.props.history.push('/help');
	}

	handleGoBack() {
		this.props.history.goBack();
	}

	render() {
		if (this.state.hasError) {
			// UI de repli.
			return (
				<>
					<Title title="Une erreur est survenue" />
					<p className="mb-3">
						Nous sommes désolé pour le désagrément, une erreur est venue
						corrompre votre interface.
						<br /> Nous faisons notre maximum pour la corriger au plus vite.
						<br /> Vous pouvez essayer de rafraîchir la page, si le problème
						persiste veuillez nous contacter :{' '}
						<Link to="/help" onClick={this.handleContact}>
							contact@monespaceproprietaire.com
						</Link>
						.
					</p>
					<div className="mb-1">
						<p className="mb-2">Pour tenter de résoudre le problème :</p>
						<button
							onClick={this.handleRefresh}
							className="btn main-btn mr-3 mb-3"
						>
							Rafraîchir la page
						</button>
						<button onClick={this.handleGoBack} className="btn main-btn mb-3">
							Revenir en arrière
						</button>
					</div>
					<div className="mb-3">
						<p className="mb-2">
							Si le problème persiste ou qu'il impacte vos données :
						</p>
						<button onClick={this.handleContact} className="btn main-btn">
							Nous contacter
						</button>
					</div>
				</>
			);
		}

		return this.props.children;
	}
}
export default withRouter(ErrorBoundary);
