// module
import React, { Component } from 'react';
// components
import { TableBase, colorOddRows } from './Table';
import TdAmount from './TdAmount';
// const
import { API_PDF_SRC_URL } from '../../constants/api';

import FileDownloadedFromAPI from '../FileDownloadedFromAPI';

class TableAccount extends Component {
	render() {
		console.log(this.props.table);
		return (
			<TableBase className="table-account" cols={this.props.cols}>
				<tbody>
					{this.props.table.map((el, i) => (
						<tr key={i} className={colorOddRows(i)}>
							<td>{el.date}</td>
							<td className="td-label">{el.label}</td>
							{el.amount ? (
								<TdAmount value={el.amount} />
							) : el.debit !== 0 ? (
								<TdAmount value={el.debit} negative={true} />
							) : (
								<TdAmount value={el.credit} />
							)}
							{this.props.solde && <TdAmount value={el.solde} />}
							<td>
								<div className="d-flex g-2">
									{!!el.gl_appel && (
										<FileDownloadedFromAPI
											uri={
												`/calls-pdf?`
												+ `activity=${this.props.activity}`
												+ `&id_call=${el.gl_appel}`
											}
											filename={`appel-loyer-${el.date}`}
											className="picto-table-wrapper"
										>
											<img
												src="/images/pictogrammes/file-blue.svg"
												alt="Télécharger l'appel de loyer"
												className={`picto-table`}
												title="Appel pdf"
											/>
										</FileDownloadedFromAPI>
									)}
									{!!el.gl_quittance && (
										<FileDownloadedFromAPI
											uri={
												`/calls-receipt?`
												+ `activity=${this.props.activity}`
												+ `&id_call=${el.gl_quittance}`
											}
											filename={`quittance-${el.date}`}
											className="picto-table-wrapper"
										>
											<img
												src="/images/pictogrammes/file-blue.svg"
												alt="Télécharger la quittance"
												className={`picto-table`}
												title="Quittance pdf"
											/>
										</FileDownloadedFromAPI>
									)}
									{el.files.length
										? el.files.map((file, i) => (
												<a
													key={i}
													href={API_PDF_SRC_URL + file.url}
													title={file.label}
													className="table-picto"
												>
													{file.label}
												</a>
										  ))
										: null}
								</div>
							</td>
						</tr>
					))}
				</tbody>
			</TableBase>
		);
	}
}

export default TableAccount;
