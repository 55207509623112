import * as types from '../action_types/filesActionTypes';

export const updateFolder = (index, value) => ({
	type: types.UPDATE_FOLDER,
	index,
	value,
});
export const resetFolders = () => ({
	type: types.RESET_FOLDERS,
});
export const setFolders = folder => ({
	type: types.SET_FOLDERS,
	folder,
});
