// module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
// component
import DashBoard from '../property/DashBoard';
import MyData from '../property/data/MyData';
import MyLeases from '../property/data/MyLeases';
import MyLots from '../property/data/MyLots';
import MyBank from '../property/MyBank';
import Incidents from '../property/Incidents';
import MyAccount from '../property/account/MyAccount';
import Tenants from '../property/account/Tenants';
import TenantAccount from '../property/account/TenantAccount';
import MyOwnerAccount from '../property/account/MyOwnerAccount';
import MyManagment from '../property/managment/MyManagment';
import MyHonoraries from '../property/managment/MyHonoraries';
import Profit from '../property/managment/Profit';
import MyFiles from '../property/MyFiles';
import Contact from '../property/contact/Contact';
import clearPropertyActions from '../../constants/clearPropertyActionList';
import MyAdministrative from '../property/administrative/MyAdministrative';
import MyReminders from '../property/administrative/MyReminders';
import MyJudicialProcedures from '../property/administrative/MyJudicialProcedures';
//actions
import { setCurrentProperty } from '../../action_creators/propertiesActionCreators';
import MyLeasesDetails from '../property/data/MyLeasesDetails';
import MyCRG from '../property/managment/MyCRG';
import ReportIncident from '../property/ReportIncident';

class PropertyRouter extends Component {
	UNSAFE_componentWillMount() {
		// Clear store from other properties datas
		Object.keys(clearPropertyActions).forEach(action => {
			this.props[action]();
		});
		if (Object.keys(this.props.properties.list).length) {
			const current_property_id = this.props.match.params.id;
			if (current_property_id) {
				const current_property = this.props.properties.list[current_property_id];
				if (current_property) {
					this.props.setCurrentProperty(current_property);
					return;
				}
			}
		}
		this.props.history.push('/');
	}

	render() {
		if (Object.keys(this.props.properties.current).length) {
			return (
				<Switch>
					{/* DashBoard */}
					<Route exact path="/property/:id/dashboard">
						<DashBoard />
					</Route>
					<Route exact path="/property/:id/dashboard/bank">
						<MyBank />
					</Route>
					{/* Data */}
					<Route exact path="/property/:id/data">
						<MyData />
					</Route>
					<Route exact path="/property/:id/data/leases">
						<MyLeases />
					</Route>
					<Route exact path="/property/:id/data/leases/:leaseId">
						<MyLeasesDetails />
					</Route>
					<Route exact path="/property/:id/data/lots">
						<MyLots />
					</Route>
					{/* Incidents */}
					<Route exact path="/property/:id/incidents">
						<Incidents />
					</Route>
					<Route exact path="/property/:id/incidents/report">
						<ReportIncident />
					</Route>
					<Route exact path="/property/:id/incidents/:page">
						<Incidents />
					</Route>
					{/* Account */}
					<Route exact path="/property/:id/account">
						<MyAccount />
					</Route>
					<Route exact path="/property/:id/account/owner_account">
						<MyOwnerAccount />
					</Route>
					<Route exact path="/property/:id/account/tenants">
						<Tenants />
					</Route>
					<Route exact path="/property/:id/account/tenants/:page">
						<Tenants />
					</Route>
					<Route exact path="/property/:id/account/tenant_account/:tenant_id">
						<TenantAccount />
					</Route>
					{/* Managment */}
					<Route exact path="/property/:id/managment">
						<MyManagment />
					</Route>
					<Route exact path="/property/:id/managment/crg">
						<MyCRG />
					</Route>
					<Route exact path="/property/:id/managment/honoraries">
						<MyHonoraries />
					</Route>
					<Route exact path="/property/:id/managment/profit">
						<Profit />
					</Route>
					{/* Bad News */}
					<Route exact path="/property/:id/administrative">
						<MyAdministrative />
					</Route>
					<Route exact path="/property/:id/bad_news/reminders">
						<MyReminders />
					</Route>
					<Route exact path="/property/:id/bad_news/judicial_procedures">
						<MyJudicialProcedures />
					</Route>
					{/* Files */}
					<Route exact path="/property/:id/files">
						<MyFiles />
					</Route>
					{/* Contact */}
					<Route exact path="/property/:id/contact">
						<Contact />
					</Route>
					{/* Redirection */}
					<Route path="/property/:id">
						<Redirect
							to={`/property/${this.props.match.params.id}/dashboard`}
						/>
					</Route>
				</Switch>
			);
		} else {
			return null;
		}
	}
}

const mapStateToProps = state => {
	return {
		properties: state.properties,
	};
};
const mapDispatchToProps = Object.assign({ setCurrentProperty }, clearPropertyActions);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PropertyRouter));
