// module
import React from 'react';
import { Link } from 'react-router-dom';

const Property = ({ property }) => (
	<div className="property-teaser">
		<h2>
			<Link to={`/property/${property.id}/dashboard`} className="extended-link">
				{property.name}
			</Link>
		</h2>
		<div className="property_nb_lot">
			<span>Nombre de lots détenus : {property.nb_lots}</span>
		</div>
	</div>
);

export default Property;
