// module
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
// component
import Form from '../../components/form/Form';
import Loading from '../../components/Loading';
// actions
import { setLog } from '../../action_creators/logActionCreators';
import { postRequest, responseManagment } from '../../functions/ajax';

const form_items = [
	{
		type: 'text',
		name: 'username',
		placeholder: 'Identifiant / email',
	},
];

class ForgottenPassword extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.resetPassword = this.resetPassword.bind(this);
		this.goBack = this.goBack.bind(this);
	}

	UNSAFE_componentWillMount() {
		// If user is already connected redirect him to racine
		if (Object.keys(this.props.user).length) {
			this.props.history.push('/');
		}
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async resetPassword(value) {
		this.setState({ is_loading: true });
		const response = await postRequest(`/forgotten-password`, value);
		if (this.responseManagment(response)) {
			this.props.setLog({
				type: 'success',
				message: response.message,
			});
		}

		if (this._isMounted) {
			this.setState({
				is_loading: false,
				display_password: true,
			});
		}
	}

	goBack(evt) {
		this.props.history.push('/login');
	}

	render() {
		return (
			<div className="page">
				<div className="login">
					<div className="page-title">
						<h2>Connexion à mon espace propriétaire</h2>
					</div>
					<div className="login-wrapper">
						<div className="segment">
							{this.state.is_loading === false ? (
								<Fragment>
									<div className="d-flex flex-row">
										<img
											onClick={this.goBack}
											src="/images/pictogrammes/back-arrow-lightblue.svg"
											alt="retour"
										/>
										<p className="font-weight-bold">
											Mot de passe oublié
										</p>
									</div>
									<p>
										Veuillez remplir votre identifiant, pour que votre
										nouveau mot de passe vous soit communiqué.
									</p>
									<Form
										form_items={form_items}
										callback={this.resetPassword}
									/>
								</Fragment>
							) : (
								<Loading />
							)}
						</div>
						<div className="legal-links">
							<Link to="/legal-notice">Mentions Légales</Link>
							<a
								href="/pdf/CGU-MonEspaceProprietaire.pdf"
								// eslint-disable-next-line react/jsx-no-target-blank
								target="_blank"
							>
								Conditions Générales d'Utilisation
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.user,
	};
};

const mapDispatchToProps = {
	setLog,
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ForgottenPassword)
);
