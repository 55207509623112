import React from 'react';

const FileToggleButton = ({ layout, onClick }) => {
	return (
		<div className="file-toggle">
			<button
				className={`file-table ${layout === 'table' ? 'active' : ''}`}
				onClick={() => onClick('table')}
			>
				{layout === 'table' && (
					<img
						src="/images/pictogrammes/checked-white.svg"
						className="profile-img img-fluid"
						alt="Check"
					/>
				)}
				<img
					src="/images/pictogrammes/bars-white.svg"
					className="profile-img img-fluid"
					alt="Bars"
				/>
			</button>
			<button
				className={`file-list ${layout === 'grid' ? 'active' : ''}`}
				onClick={() => onClick('grid')}
			>
				{layout === 'grid' && (
					<img
						src="/images/pictogrammes/checked-white.svg"
						className="profile-img img-fluid"
						alt="Check"
					/>
				)}
				<img
					src="/images/pictogrammes/table-cells-white.svg"
					className="profile-img img-fluid"
					alt="Table cells"
				/>
			</button>
		</div>
	);
};

export default FileToggleButton;
