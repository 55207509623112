import React, { Component } from 'react';
import Title from '../../../components/Title';
import { SelectorItemObject } from '../../../components/form/item/SelectorItem';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import TableCrg from '../../../components/table/TableCrg';
import Loading from '../../../components/Loading';
import { getRequest, responseManagment } from '../../../functions/ajax';
import {
	setCrg,
	setCrgCurrentExercice,
	setCrgExercices,
} from '../../../action_creators/crgActionCreators';
import { setLog } from '../../../action_creators/logActionCreators';

class MyCRG extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.state = {
			is_loading_crg: false,
		};

		this.goBack = this.goBack.bind(this);
		this.responseManagment = responseManagment.bind(this);
		this.handleExercice = this.handleExercice.bind(this);
	}

	UNSAFE_componentWillMount() {
		if (!this.props.crg.exercices.length) {
			this.getCrg();
		}
	}

	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps) {
		const crg = this.props.crg;
		// Filter change exercice
		if (
			prevProps.crg.current_exercice !== crg.current_exercice
			|| prevProps.crg.current_lot !== crg.current_lot
		) {
			this.updateCrg();
		}
	}

	handleExercice(evt) {
		this.props.setCrgCurrentExercice(evt.target.value);
	}

	async getCrg() {
		if (!this.props.crg.list.length) {
			this.setState({ is_loading_crg: true });
			const response = await getRequest(
				`/crg-par-periode?activity=${this.props.properties.current.id}`,
				this.props.user.token
			);
			if (this.responseManagment(response)) {
				this.props.setCrg(response.crg);
				this.props.setCrgExercices(response.exercices);
			}

			if (this._isMounted) {
				this.setState({ is_loading_crg: false });
			}
		}
	}

	async updateCrg() {
		this.setState({ is_loading: true });
		const current_exercice =
			this.props.crg.exercices[this.props.crg.current_exercice];
		const response = await getRequest(
			`/crg-par-periode`
				+ `?activity=${this.props.properties.current.id}`
				+ `&start=${current_exercice.start}`
				+ `&end=${current_exercice.end}`,
			this.props.user.token
		);

		if (this.responseManagment(response)) {
			this.props.setCrg(response.crg);
		} else {
			this.props.setCrg({});
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	goBack(evt) {
		this.props.history.replace(
			`/property/${this.props.properties.current.id}/managment`
		);
	}

	render() {
		return (
			<div className='my-gestion'>
				<Title title="Ma gestion" />
				<div className="page-title">
					<button className="btn back-btn" onClick={evt => this.goBack(evt)}>
						<img
							src="/images/pictogrammes/back-arrow-blue.svg"
							alt="retour"
						/>
					</button>
					<h2>Comptes rendus de gestion</h2>
				</div>
				<div className="standfirst">
					{this.props.crg.exercices.length > 0 && (
						<SelectorItemObject
							id="select-profit-exerice"
							label="Période"
							current={this.props.crg.current_exercice}
							options={this.props.crg.exercices}
							callback={this.handleExercice}
						/>
					)}
				</div>
				{this.state.is_loading_crg === false ? (
					this.props.crg.list.length ? (
						<TableCrg
							activity={this.props.properties.current.id}
							cols={['DATE DE DÉBUT', 'DATE DE FIN', 'DOCUMENTS']}
							table={this.props.crg.list}
						/>
					) : (
						<p>Aucun CRG disponible.</p>
					)
				) : (
					<Loading />
				)}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		profit: state.profit,
		properties: state.properties,
		user: state.user,
		crg: state.crg,
	};
};

const mapDispatchToProps = {
	setCrg,
	setCrgExercices,
	setCrgCurrentExercice,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyCRG));
