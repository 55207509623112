// module
import React from 'react';
// function
import { formatNumbers } from '../../functions';

export function colorAmounts(number, negatively) {
	// Set red color to negative numbers
	if (parseFloat(number) < 0) return negatively ? 'positive' : 'negative';
	if (parseFloat(number) > 0) return negatively ? 'negative' : 'positive';
	return 'neutral';
}

const TdAmount = props => (
	<td className={`amount amount-${colorAmounts(props.value, props.negative)}`}>
		{props.negative && props.value !== 0 && '-'}
		{formatNumbers(props.value)}&nbsp;€
	</td>
);
export default TdAmount;

export const TdAmountAnimated = props => (
	<td className={`amount amount-${colorAmounts(props.value, props.negative)}`}>
		<div className="animated-cell">{formatNumbers(props.value)}&nbsp;€</div>
	</td>
);
