// module
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// components
import { TableBase, colorOddRows } from './Table';
import { formateStringDate } from '../../functions';
import FileDownloadedFromAPI from '../FileDownloadedFromAPI';
import DropdownButton from '../button/DropdownButton';

class TableCrg extends Component {
	constructor(props) {
		super(props);

		this.state = {
			indexOpen: null,
		};

		this.handleDropDown = this.handleDropDown.bind(this);
	}

	handleDropDown(i) {
		this.setState(prevState => ({
			indexOpen: prevState.indexOpen === i ? null : i,
		}));
	}

	render() {
		const { indexOpen } = this.state;
		return (
			<div
				className={`table-wrapper ${indexOpen == null ? '' : 'overflow-padding'}`}
			>
				<TableBase className="table-crg" cols={this.props.cols}>
					<tbody>
						{this.props.table.map((crg, i) => (
							<RowCrg
								key={i}
								crg={crg}
								i={i}
								activity={this.props.activity}
								handleDropDown={this.handleDropDown}
								indexOpen={indexOpen}
							/>
						))}
					</tbody>
				</TableBase>
			</div>
		);
	}
}

class RowCrg extends Component {
	render() {
		const yearFoncier = this.props.crg.foncier
			? this.props.crg.foncier.slice(0, 4)
			: null;
		const endYear = this.props.crg.end.slice(0, 4);
		const annual = this.props.crg.end.endsWith('-12-31');

		return (
			<tr className={colorOddRows(this.props.i)}>
				<td className="date">{formateStringDate(this.props.crg.start)}</td>
				<td className="date">{formateStringDate(this.props.crg.end)}</td>
				<td>
					<DropdownButton
						open={this.props.i === this.props.indexOpen}
						toggle={() => this.props.handleDropDown(this.props.i)}
						onBlur={() => this.props.handleDropDown(this.props.i)}
						items={[
							<FileDownloadedFromAPI
								uri={
									`/crg-pdf?`
									+ `activity=${this.props.activity}`
									+ `&id_exercice=${this.props.crg.id}`
								}
								filename={`CRG-${this.props.crg.start}-${this.props.crg.end}`}
							>
								<span>
									Compte rendu de gestion de {this.props.crg.start} en
									paysage
								</span>
							</FileDownloadedFromAPI>,
							<FileDownloadedFromAPI
								uri={
									`/crg-pdf?`
									+ `activity=${this.props.activity}`
									+ `&id_exercice=${this.props.crg.id}`
									+ `&portrait=1`
								}
								filename={`CRG-${this.props.crg.start}-${this.props.crg.end}`}
							>
								<span>
									Compte rendu de gestion de {this.props.crg.start} en
									portrait
								</span>
							</FileDownloadedFromAPI>,
							yearFoncier ? (
								<FileDownloadedFromAPI
									uri={
										`/crg-pdf?`
										+ `activity=${this.props.activity}`
										+ `&id_exercice=${this.props.crg.id}`
										+ `&foncier=true`
									}
									filename={`Declaration-revenus-foncier-${yearFoncier}`}
									className="picto-table-wrapper"
								>
									<span>
										Déclaration des revenus fonciers de {yearFoncier}
									</span>
								</FileDownloadedFromAPI>
							) : null,
							annual && (
								<FileDownloadedFromAPI
									uri={
										`/crg-pdf?`
										+ `activity=${this.props.activity}`
										+ `&id_exercice=${this.props.crg.id}`
										+ `&annual=true`
									}
									filename={`CRG-annuel-${endYear}`}
									className="picto-table-wrapper"
								>
									<span>
										Compte rendu de gestion annuel de {endYear} en
										paysage
									</span>
								</FileDownloadedFromAPI>
							),
							annual && (
								<FileDownloadedFromAPI
									uri={
										`/crg-pdf?`
										+ `activity=${this.props.activity}`
										+ `&id_exercice=${this.props.crg.id}`
										+ `&annual=true`
										+ `&portrait=true`
									}
									filename={`CRG-annuel-${endYear}`}
									className="picto-table-wrapper"
								>
									<span>
										Compte rendu de gestion annuel de {endYear} en
										portrait
									</span>
								</FileDownloadedFromAPI>
							),
						]}
					>
						<img
							src={`/images/pictogrammes/file-blue.svg`}
							alt="Option de téléchargement"
							title="Option de téléchargement"
							className="table-picto"
						/>
					</DropdownButton>
				</td>
			</tr>
		);
	}
}

TableCrg.propTypes = {
	// ...Table.propTypes,
	activity: PropTypes.number.isRequired,
};

export default TableCrg;
