import React, { Component } from 'react';
import Title from '../../../components/Title';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getRequest, responseManagment } from '../../../functions/ajax';
import {
	setCurrentLease,
	setTechnicalVisits,
} from '../../../action_creators/leasesActionCreators';
import { setLog } from '../../../action_creators/logActionCreators';
import TableLease from '../../../components/table/TableLease';
import TableRent from '../../../components/table/TableRent';
import Loading from '../../../components/Loading';
import TableTechnicalVisits from '../../../components/table/TableTechnicalVisits';

class MyLeasesDetails extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading_lease: false,
			is_loading_technical_visits: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.getLeaseDetails = this.getLeaseDetails.bind(this);
		this.goBack = this.goBack.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.getLeaseDetails(this.props.match.params.leaseId);
		this.getTechnicalVisits();
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async getLeaseDetails(id) {
		this.setState({ is_loading_lease: true });
		const response = await getRequest(
			`/leases/${id}?activity=${this.props.properties.current.id}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setCurrentLease(response.lease);
		}

		if (this._isMounted) {
			this.setState({ is_loading_lease: false });
		}
	}

	async getTechnicalVisits() {
		this.setState({ is_loading_technical_visits: true });
		const response = await getRequest(
			`/visites?activity=${this.props.properties.current.id}&start=2024-01-01&end=2024-12-31`,
			this.props.user.token
		);

		if (this.responseManagment(response)) {
			this.props.setTechnicalVisits(response.visites);
		}

		if (this._isMounted) {
			this.setState({ is_loading_technical_visits: false });
		}
	}

	goBack(evt) {
		this.props.history.goBack();
	}

	render() {
		return (
			<>
				<Title title="Mes biens" />
				<div className="my-lease-details">
					<div className="page-title">
						<button
							className="btn back-btn"
							onClick={evt => this.goBack(evt)}
						>
							<img
								src="/images/pictogrammes/back-arrow-blue.svg"
								alt="retour"
							/>
						</button>
						{this.state.is_loading_lease === false && (
							<h2>Détails - {this.props.leases.current.label}</h2>
						)}
					</div>
					<div className="lease-details responsive-column">
						<div className="card lease-insurance">
							<div className="card-header">
								<h2>Bail & assurance</h2>
							</div>
							<div className="card-body table-wrapper">
								{this.state.is_loading_lease === false ? (
									<TableLease table={this.props.leases.current} />
								) : (
									<Loading />
								)}
							</div>
						</div>
						<div className="card rent">
							<div className="card-header">
								<h2>Le loyer</h2>
							</div>
							<div className="card-body table-wrapper">
								{this.state.is_loading_lease === false ? (
									<TableRent table={this.props.leases.current} />
								) : (
									<Loading />
								)}
							</div>
						</div>
						<div className="card technical-visits">
							<div className="card-header">
								<h2>Visites techniques</h2>
							</div>
							<div className="card-body table-wrapper">
								{this.state.is_loading_technical_visits === false ? (
									this.props.leases.technical_visits.length ? (
										<TableTechnicalVisits
											table={this.props.leases.technical_visits}
											activity={this.props.properties.current.id}
										/>
									) : (
										<p>Aucune visite technique.</p>
									)
								) : (
									<Loading />
								)}
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		properties: state.properties,
		leases: state.leases,
		user: state.user,
	};
};

const mapDispacthToProps = {
	setCurrentLease,
	setTechnicalVisits,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(MyLeasesDetails));
