import * as types from '../action_types/leasesActionTypes';

export const setLeases = leases => ({
	type: types.SET_LEASES,
	leases,
});
export const removeLeases = () => ({
	type: types.REMOVE_LEASES,
});

export const setCurrentLease = lease => ({
	type: types.SET_CURRENT_LEASE,
	lease,
});
export const removeCurrentLease = () => ({
	type: types.REMOVE_CURRENT_LEASE,
});
export const setTechnicalVisits = technical_visits => ({
	type: types.SET_TECHNICAL_VISITS,
	technical_visits,
});