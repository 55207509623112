// module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
// component
import Form from '../../../components/form/Form';
import Loading from '../../../components/Loading';
// actions
import { setLog } from '../../../action_creators/logActionCreators';
// functions
import { postRequest, responseManagment } from '../../../functions/ajax';
import Title from '../../../components/Title';
import Contact from './Contact';

class Help extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      is_loading: false,
    };

    this.postMessage = this.postMessage.bind(this);
    this.responseManagment = responseManagment.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  async postMessage(form_value) {
    this.setState({ is_loading: true });
    const response = await postRequest(
      `/message-client?`
        + (this.props.properties.current.id
          ? `activity=${this.props.properties.current.id}`
          : ''),
      form_value,
      this.props.user.token
    );
    if (this.responseManagment(response)) {
      this.props.setLog({
        type: 'success',
        message:
          'Message envoyé avec succès. Nous vous répondrons dans les plus bref délais.',
      });
    }

    if (this._isMounted) {
      this.setState({ is_loading: false });
    }
  }

  render() {
    return (
		<>
			<Title title="Aide & contact" />
			<div className="contact">
				<div className="guide">
					<p>
						Pour vous aider à naviguer sur votre espace propriétaire, vous pouvez
						télécharger notre guide.
					</p>
					<a
						className="btn main-btn"
						href={`/pdf/MY${
							this.props.properties.current.sousTraitance === true
								? 'GP'
								: ''
						}-Guide-Proprietaire.pdf`}
						target="_blank"
						rel="noopener noreferrer"
					>
						Télécharger le guide
					</a>
				</div>
				<div className="contact-forms responsive-column">
					{!!Object.entries(this.props.properties.current).length && (
						<Contact />
					)}
					<div className="segment">
						<h3>Un problème techinque ?</h3>
						{this.state.is_loading === false ? (
							<Form
								form_items={[
									{
										type: 'text',
										name: 'object',
										label: `Objet`,
										required: true,
									},
									{
										type: 'textarea',
										name: 'message',
										label: `Message`,
										requied: true,
									},
								]}
								callback={this.postMessage}
							/>
						) : (
							<Loading />
						)}
					</div>
				</div>
				<div className="legal-links">
					<Link to="/legal-notice">Mentions Légales</Link>
					<a
						href="/pdf/CGU-MonEspaceProprietaire.pdf"
						// eslint-disable-next-line react/jsx-no-target-blank
						target="_blank"
					>
						Conditions Générales d'Utilisation
					</a>
				</div>
			</div>
		</>
	);
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    properties: state.properties,
  };
};
const mapDispatchToProps = {
  setLog,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Help));
