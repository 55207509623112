// module
import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';

class DropdownButton extends Component {
	menuRef = createRef();

	componentDidUpdate(prevProps) {
		if (prevProps.open !== this.props.open && this.props.open) {
			this.menuRef.current.focus();
		}
	}

	render() {
		return (
			<div
				className={
					'dropdown d-sm-inline-block' + (this.props.open ? ' show' : '')
				}
			>
				<button
					className="btn btn-primary" /* dropdown-toggle */
					type="button"
					id="dropdownMenuButton"
					data-toggle="dropdown"
					aria-haspopup="true"
					aria-expanded="false"
					//onClick={this.props.toggle}
					onMouseEnter={this.props.toggle}
					//onMouseLeave={this.props.toggle}
				>
					{this.props.children}
				</button>
				<div
					tabIndex={0}
					ref={this.menuRef}
					className={'dropdown-menu right' + (this.props.open ? ' show' : '')}
					aria-labelledby="dropdownMenuButton"
					//onBlur={this.props.open ? this.props.onBlur : undefined}
					onMouseLeave={this.props.toggle}
				>
					{this.props.items.map((item, i) =>
						item ? (
							<div key={i} className="dropdown-item">
								{item}
							</div>
						) : null
					)}
				</div>
			</div>
		);
	}
}

DropdownButton.defaultProps = {
	open: false,
};
DropdownButton.propTypes = {
	open: PropTypes.bool,
	toggle: PropTypes.func.isRequired,
	items: PropTypes.arrayOf(PropTypes.node).isRequired,
	children: PropTypes.node.isRequired,
};

export default DropdownButton;
