import React from 'react';
import FileDownloadedFromAPI from '../FileDownloadedFromAPI';

const TableTechnicalVisits = ({ table, activity }) => {
	return (
		<table className="table table-technical-visits">
			<thead>
				<tr>
					<th>Libellé</th>
					<th>Date</th>
					<th>Pièce</th>
				</tr>
			</thead>
			<tbody className="reverse-color">
				{table.map((visit, index) => (
					<tr key={index}>
						<td>{visit.label}</td>
						<td>{visit.date}</td>
						<td>
							<FileDownloadedFromAPI
								uri={`/visites-pdf?activity=${activity}&id=${visit.id}`}
								filename={visit.label + '_' + visit.date}
							>
								<img
									src="/images/pictogrammes/file-blue.svg"
									alt="Télécharger la visite technique"
									title={`Visite technique du ${visit.date}`}
									className="table-picto"
								/>
							</FileDownloadedFromAPI>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default TableTechnicalVisits;
