//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
// components
import Loading from '../../../components/Loading';
import TableTenants from '../../../components/table/TableTenants';
import OwnerAccountCard from './cards/OwnerAccountCard';
import Title from '../../../components/Title';
// action
import {
	setTenants,
	setSoldeTenants,
	setTenantsExercice,
} from '../../../action_creators/tenantsActionCreators';
import { setLog } from '../../../action_creators/logActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';
import { formatNumbers } from '../../../functions';

class MyAccount extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		};

		this.responseManagment = responseManagment.bind(this);
	}

	UNSAFE_componentWillMount() {
		if (!Object.keys(this.props.tenants.list).length) {
			this.getTenants();
		}
	}

	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async getTenants() {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/compte-locataire?activity=${this.props.properties.current.id}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setTenants(response.tenants, response.current_page);
			this.props.setSoldeTenants(response.solde, response.solde_tresorerie);
			this.props.setTenantsExercice(response.exercice);
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	render() {
		return (
			<>
				<Title title="Mes comptes" />
				<div className="my-account responsive-column">
					<OwnerAccountCard />
					<div className="card unscrollable tenants-account">
						<div className="card-header">
							<h2>État comptes locataires</h2>
						</div>
						<div
							className={
								'card-body'
								+ (this.props.tenants.solde_treasury
								|| this.props.tenants.solde_treasury === 0
									? ' solde-tenants-global'
									: '')
							}
						>
							{this.state.is_loading === false ? (
								this.props.tenants.solde_treasury
								|| this.props.tenants.solde_treasury === 0 ? (
									<>
										<h3>
											{formatNumbers(
												Math.abs(
													this.props.tenants.solde_treasury
												)
											)}{' '}
											€
										</h3>
										<p>Solde global dû par vos locataires</p>
									</>
								) : (
									<p>Solde indisponible</p>
								)
							) : (
								<Loading />
							)}
						</div>
					</div>
					<div className="card debitors-account">
						<div className="card-header">
							<h2>Comptes locataires débiteurs</h2>
						</div>
						<div className="card-body">
							{this.state.is_loading === false ? (
								this.props.tenants.list[1]
								&& this.props.tenants.list[1].length ? (
									<>
										<div className="table-wrapper">
											<TableTenants
												cols={[
													'NOM',
													'SOLDE',
													'COMPTE',
												]}
												table={this.props.tenants.list[1]}
												solde={this.props.tenants.solde}
												solde_treasury={
													this.props.tenants.solde_treasury
												}
												url_access={`/property/${this.props.match.params.id}/account/tenant_account/`}
												inCard
											/>
										</div>
										<div className="btn-wrapper">
											<Link
												className="btn main-btn"
												to={`/property/${this.props.match.params.id}/account/tenants`}
											>
												Voir tous les comptes locataires
											</Link>
										</div>
									</>
								) : (
									<p>Il n'y aucun locataire.</p>
								)
							) : (
								<Loading />
							)}
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		properties: state.properties,
		user: state.user,
		tenants: state.tenants,
	};
};

const mapDispacthToProps = {
	setTenants,
	setSoldeTenants,
	setTenantsExercice,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(MyAccount));
