// module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
// component
import Form from '../../components/form/Form';
import Loading from '../../components/Loading';
// actions
import {
	setUser,
	setUserProfilePicture,
	removeUser,
} from '../../action_creators/userActionCreators';
import {
	setProperties,
	removeProperties,
} from '../../action_creators/propertiesActionCreators';
import clearPropertyActions from '../../constants/clearPropertyActionList';
import { setLog } from '../../action_creators/logActionCreators';
// functions
import { getProperties } from '../../functions/properties';
import { getProfilePicture } from '../../functions/user';
import {
	getRequest,
	postRequest,
	responseManagment,
	responseBlobManagment,
} from '../../functions/ajax';
// constants
import { STORED_USER, STORED_PROPERTIES } from '../../constants/localStorage';

const form_items = [
	{
		type: 'text',
		name: 'login',
		label: `Identifiant`,
		required: true,
	},
	{
		type: 'password',
		name: 'password',
		label: `Mot de passe`,
		required: true,
	},
];

class Login extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.responseBlobManagment = responseBlobManagment.bind(this);
		this.getProperties = getProperties.bind(this);
		this.getProfilePicture = getProfilePicture.bind(this);
		this.connection = this.connection.bind(this);
	}

	UNSAFE_componentWillMount() {
		// Logout a connectec user that go on this page
		if (Object.keys(this.props.user).length) {
			this.logout();
		}
		// Login with token from another website
		if (this.props.match.params.token) {
			this.setState({ is_loading: true });
			this.initUser(this.props.match.params.token);
		}
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	// Uses form values to get User information from API
	async connection(value) {
		this.setState({ is_loading: true });
		// Get token with form value
		const token = await this.getToken(value);
		if (token) {
			this.initUser(token);
		} else if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	logout() {
		this.props.removeProperties();
		this.props.removeUser();
		Object.keys(clearPropertyActions).forEach(action => {
			this.props[action]();
		});
		localStorage.removeItem(STORED_USER);
		localStorage.removeItem(STORED_PROPERTIES);
		localStorage.clear();
	}

	async initUser(token) {
		// Get user with token
		const user = await this.getUser(token);
		if (user) {
			// Properties are loaded here because they couldn't have been loaded by App
			await this.getProperties(user.token);
			this.getProfilePicture(user.token);
			this.props.history.push('/');
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	// Get Token from API
	async getToken(value) {
		const response = await postRequest(`/login`, value);
		if (this.responseManagment(response)) return response.token;
		return null;
	}

	// Get User from API and set it to Store and Local Storage
	async getUser(token) {
		const response = await getRequest(`/user`, token);
		if (!this.responseManagment(response)) return null;
		const user = {
			name: response.name,
			first_name: response.first_name,
			email: response.email,
			phone: response.phone,
			cgu: response.cgu_check,
			token: token,
		};
		this.props.setUser(user);
		localStorage.setItem(STORED_USER, JSON.stringify(user));
		return user;
	}

	render() {
		return (
			<div className="page">
				<div className="login">
					<div className="page-title">
						<h2>Connexion à mon espace propriétaire</h2>
					</div>
					<div className="login-wrapper">
						<div className="segment">
							{this.state.is_loading === false ? (
								<Form form_items={form_items} callback={this.connection}>
									<Link to="forgotten-password" className="forgot">
										Mot de passe oublié ?
									</Link>
								</Form>
							) : (
								<Loading />
							)}
						</div>
						<div className="legal-links">
							<Link to="/legal-notice">Mentions Légales</Link>
							<a
								href="/pdf/CGU-MonEspaceProprietaire.pdf"
								// eslint-disable-next-line react/jsx-no-target-blank
								target="_blank"
							>
								Conditions Générales d'Utilisation
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.user,
		properties: state.properties,
	};
};

const mapDispatchToProps = Object.assign(
	{
		setUser,
		setUserProfilePicture,
		removeUser,
		setProperties,
		removeProperties,
		setLog,
	},
	clearPropertyActions
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
