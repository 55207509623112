// module
import React from 'react';
import PropTypes from 'prop-types';

function PrintButton(props) {
	return (
		<button className="btn btn-print outline-btn white" onClick={props.print}>
			Imprimer
		</button>
	);
}

PrintButton.propTypes = {
	print: PropTypes.func.isRequired,
};

export default PrintButton;
