//module
import React from 'react';

const Notification = props => (
	<div className="notification">
		<p>
			<span>{props.title}&nbsp;:</span> {props.children}
		</p>
	</div>
);

export default Notification;
