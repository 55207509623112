// module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import Table from '../../../components/table/Table';
import Loading from '../../../components/Loading';
import PrintButton from '../../../components/button/PrintButton';
//action
import { setLots } from '../../../action_creators/lotsActionCreators';
import { setLog } from '../../../action_creators/logActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';
import { printTable } from '../../../functions/print';
import Title from '../../../components/Title';

class MyLots extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		};

		this.handlePrint = this.handlePrint.bind(this);
		this.goBack = this.goBack.bind(this);
		this.responseManagment = responseManagment.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.getLots();
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async getLots() {
		if (!this.props.lots.length) {
			this.setState({ is_loading: true });
			const response = await getRequest(
				`/lot?activity=${this.props.properties.current.id}`,
				this.props.user.token
			);
			if (this.responseManagment(response)) {
				this.props.setLots(response.lots);
			}

			if (this._isMounted) {
				this.setState({ is_loading: false });
			}
		}
	}

	handlePrint(evt) {
		printTable(
			'table-lots',
			'lots-propriete.pdf',
			'Mes lots',
			this.props.properties.current
		);
	}

	goBack(evt) {
		this.props.history.push(`/property/${this.props.match.params.id}/data`);
	}

	render() {
		return (
			<>
				<Title title="Mes biens" />
				<div className="my-lots">
					<div className="page-title">
						<button
							className="btn back-btn"
							onClick={evt => this.goBack(evt)}
						>
							<img
								src="/images/pictogrammes/back-arrow-blue.svg"
								alt="retour"
							/>
						</button>
						<h2>Tous mes biens à la date du jour</h2>
						<PrintButton print={this.handlePrint} />
					</div>
					<div className="table-wrapper">
						{this.state.is_loading === false ? (
							this.props.lots.length ? (
								<Table
									table={this.props.lots}
									keys={['reference', 'label', 'type', 'principal']}
									cols={['REF', 'LIBELLÉ', 'TYPE', 'LOUÉ']}
									boolean={['Non', 'Oui']}
									className="table-lots"
								/>
							) : (
								<p>Vous ne possédez pas de lots.</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		properties: state.properties,
		user: state.user,
		lots: state.lots,
	};
};
const mapDispacthToProps = {
	setLots,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(MyLots));
