//module
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts'

class GraphLines extends Component {

    componentDidMount() {
        this.chart = Highcharts.chart(this.props.id, {
            chart: {
                backgroundColor: "#f9fafd",
                type: "line"
            },
            title: { "text": this.props.title },
            series: this.props.series,
            tooltip: { "valueSuffix": "\u00A0€" },
            xAxis: {
                "title": { "text": null },
                "categories": this.props.x ? this.props.x : null
            },
            yAxis: {
                "title": { "text": null },
            },
            credits: {
                enabled: false
            },
        });
    }

    render() {
        return (
            <div id={this.props.id} className="graph"></div>
        );
    }
}

GraphLines.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    x: PropTypes.array.isRequired,
    series: PropTypes.array.isRequired,
};

export default GraphLines;
