import * as types from '../action_types/crgActionTypes';

const initialState = {
  short_list: [],
  count: 0,
  list: [],
  exercices: [],
  current_exercice: 0,
};

function crgReducer(state = initialState, action) {
  let nextState;

  switch (action.type) {
    case types.SET_CRG:
      nextState = {
        ...state,
        list: action.crg,
      };
      return nextState || state;

    case types.SET_CRG_SHORT:
      nextState = {
        ...state,
        short_list: action.crg,
        count: action.count,
      };
      return nextState || state;

    case types.SET_CRG_EXERCICES:
      nextState = {
        ...state,
        exercices: action.exercices || [],
      };
      return nextState || state;

    case types.SET_CRG_CURRENT_EXERCICE:
      nextState = {
        ...state,
        current_exercice: action.exercice,
      };
      return nextState || state;

    case types.REMOVE_CRG:
      return initialState;

    default:
      return state;
  }
}

export default crgReducer;
