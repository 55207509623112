// module
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// components
import { TableBase, colorOddRows } from './Table';
import TdAmount from './TdAmount';
import Coin from '../../../styles/images/pictogrammes/coin';

class TableTenants extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hoverIndex: false,
		};

		this.handleMouseEnter = this.handleMouseEnter.bind(this);
		this.handleMouseLeave = this.handleMouseLeave.bind(this);
	}

	handleMouseEnter(index) {
		this.setState({ hoverIndex: index });
	}

	handleMouseLeave() {
		this.setState({ hoverIndex: null });
	}

	render() {
		return (
			<TableBase className="table-tenants" cols={this.props.cols}>
				<tbody>
					{(this.props.inCard
						? this.props.table.filter(tenant => tenant.solde < 0)
						: this.props.table
					).map((tenant, i) => (
						<tr key={i} className={colorOddRows(i)}>
							<td className="td-align-left">{tenant.name}</td>
							<TdAmount value={tenant.solde} />
							<td>
								<Link
									to={this.props.url_access + tenant.id}
									className="d-flex justify-content-center"
								>
									<div
										onMouseEnter={() => this.handleMouseEnter(i)}
										onMouseLeave={this.handleMouseLeave}
									>
										<Coin
											color={
												this.state.hoverIndex === i
													? '#2a5edb'
													: '#193b72'
											}
										/>
									</div>
								</Link>
							</td>
						</tr>
					))}
					<tr className="total">
						<td>TOTAL</td>
						<TdAmount value={this.props.solde} />
						<td />
					</tr>
				</tbody>
			</TableBase>
		);
	}
}

export default TableTenants;
