//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import PrintButton from '../../../components/button/PrintButton';
import TableProcedures from '../../../components/table/TableProcedures';
import Loading from '../../../components/Loading';
// action
import { setLog } from '../../../action_creators/logActionCreators';
import { setProcedures } from '../../../action_creators/proceduresActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';
import { printTable } from '../../../functions/print';
import Title from '../../../components/Title';

class MyJudicialProcedures extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.handlePrint = this.handlePrint.bind(this);
		this.goBack = this.goBack.bind(this);
	}

	UNSAFE_componentWillMount() {
		if (!this.props.procedures.list.length) {
			this.getJudicialProcedures();
		}
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async getJudicialProcedures() {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/procedures-judiciaires?activity=${this.props.properties.current.id}`,
			this.props.user.token
		);

		if (this.responseManagment(response)) {
			this.props.setProcedures(response.procedures);
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	handlePrint(evt) {
		printTable(
			'table-judicial-procedures',
			'procedure-judiciaires-propriete.pdf',
			'Procédure judiciaire en cours',
			this.props.properties.current
		);
	}

	goBack(evt) {
		this.props.history.push(`/property/${this.props.match.params.id}/bad_news/`);
	}

	render() {
		return (
			<>
				<Title title="Mes relances / procédures" />
				<div className="my-bad-news">
					<div className="page-title">
						<button
							className="btn back-btn"
							onClick={evt => this.goBack(evt)}
						>
							<img
								src="/images/pictogrammes/back-arrow-blue.svg"
								alt="retour"
							/>
						</button>
						<h2>Procédure judiciaire</h2>
						<PrintButton
							picto_color={this.props.properties.current.syndic}
							print={this.handlePrint}
						/>
					</div>
				</div>
				<div className="table-wrapper">
					{this.state.is_loading === false ? (
						this.props.procedures.list.length ? (
							<TableProcedures
								table={this.props.procedures.list}
								cols={['DATE DE DÉBUT', 'LIBELLÉ', 'LOCATAIRE', 'ÉTAPES']}
							/>
						) : (
							<p>Il n'y a pas de procédure en cours.</p>
						)
					) : (
						<Loading />
					)}
				</div>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		properties: state.properties,
		user: state.user,
		procedures: state.procedures,
	};
};
const mapDispatchToProps = {
	setLog,
	setProcedures,
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(MyJudicialProcedures)
);
