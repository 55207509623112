// module
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// components
import { TableBase, colorOddRows } from './Table';
import FileDownloadedFromAPI from '../FileDownloadedFromAPI';
import TdAmount from './TdAmount';
import { API_PDF_SRC_URL } from '../../constants/api';

class TableHonoraries extends Component {
	render() {
		return (
			<TableBase className="table-honoraries" cols={this.props.cols}>
				<tbody>
					{this.props.table.map((el, i) => (
						<tr key={i} className={colorOddRows(i)}>
							<td className="date">{el.date}</td>
							<td>{el.type}</td>
							<TdAmount value={el.montant} /* negative={true} */ />
							<td>
								{el.type === 'Honoraires' ? (
									<FileDownloadedFromAPI
										uri={
											`/honoraire-facture/${el.id}?`
											+ `activity=${this.props.activity}`
										}
										filename={`${el.type}-${el.id}-${el.date}`}
										className="picto-table-wrapper"
									>
										<img
											src={`/images/pictogrammes/file-blue.svg`}
											alt="Télécharger la facture de l'honoraire"
											title={`Facture de l'honoraire du ${el.date}`}
											className="table-picto"
										/>
									</FileDownloadedFromAPI>
								) : (
									el.files.length > 0
									&& el.files.map((file, i) => (
										<a
											key={i}
											href={API_PDF_SRC_URL + file.url}
											className="table-picto"
										>
											{file.label}
										</a>
									))
								)}
							</td>
						</tr>
					))}
				</tbody>
			</TableBase>
		);
	}
}

TableHonoraries.propTypes = {
	// ...Table.propTypes,
	activity: PropTypes.number.isRequired,
};

export default TableHonoraries;
